import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { AuditLog, CourierTypeEnum, Customer, InvoiceTypeEnum, Order, OrderStatusEnum, PaymentTypeEnum, Stock, Tax, WebcoffeeLinks, WebcoffeeRoutes } from '@webcoffee/interfaces';
import { EMPTY, Observable, Subject, catchError, exhaustMap, filter, switchMap, take, takeUntil, tap } from 'rxjs';
import { ToasterService } from '../../shared/utils/services/toastr.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { RouterStore } from '../../shared/utils/services/router.store';

export interface OrderState {
    orders: Order[];
    query: { skip: number; take: number; sort: string; search?: string; active?: boolean; status: OrderStatusEnum[] };
    ordersCount: number | null;
    selectedOrder: Order | null;
    loading: boolean;
    editMode: boolean;
    customers: Customer[] | null;
    orderFormGroup: FormGroup | null;
    orderCustomer: Customer | null;
    productsList:
        | {
              id: string;
              name: string;
              sku: string;
              price: { price: number; discounted_price: true; discounted_price_from: string; tax: { id: string; rate: number } };
              stock: Stock;
          }[]
        | null;
    taxes: { name: string; percentage: number }[] | null;
    stocks: any[] | null;
    rates: { [type: string]: number } | null;
    series: { [type: string]: string[] } | null;
    addInvoice: boolean;
    addAwb: boolean;
    addAwbExtern: boolean;
    services: any[] | null;
    history: AuditLog[];
}

@Injectable()
export class OrderStore extends ComponentStore<OrderState> {
    constructor(
        private http: HttpClient,
        private toastr: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
    ) {
        super({
            orders: [],
            query: { skip: 0, take: 50, sort: '-customer_order_id', search: '', active: true, status: [] },
            ordersCount: null,
            selectedOrder: null,
            loading: false,
            editMode: false,
            customers: null,
            orderFormGroup: null,
            orderCustomer: null,
            productsList: null,
            taxes: null,
            stocks: null,
            rates: null,
            series: null,
            addInvoice: false,
            addAwb: false,
            addAwbExtern: false,
            services: null,
            history: [],
        });
    }

    readonly getOrders = this.select((state) => state.orders);
    readonly getQuery = this.select((state) => state.query);
    readonly getOrdersCount = this.select((state) => state.ordersCount);
    readonly getSelectedOrder = this.select((state) => state.selectedOrder);
    readonly getLoading = this.select((state) => state.loading);
    readonly getEditMode = this.select((state) => state.editMode);
    readonly getCustomers = this.select((state) => state.customers);
    readonly getProductsList = this.select((state) => state.productsList);
    readonly getOrderCustomer = this.select((state) => state.orderCustomer);
    readonly getOrderFormGroup = this.select((state) => state.orderFormGroup);
    readonly getTaxes = this.select((state) => state.taxes);
    readonly getStocks = this.select((state) => state.stocks);
    readonly getRates = this.select((state) => state.rates);
    readonly getSeries = this.select((state) => state.series);
    readonly getAddInvoice = this.select((state) => state.addInvoice);
    readonly getAddAwb = this.select((state) => state.addAwb);
    readonly getAddAwbExtern = this.select((state) => state.addAwbExtern);
    readonly getServices = this.select((state) => state.services);
    readonly getOrderHistory = this.select((state) => state.history);
    readonly getTotal = this.select((state) => {
        if (state.selectedOrder) {
            let total = +state.selectedOrder.total + +state.selectedOrder.shipping_cost;
            const additionalTaxes = state.selectedOrder.products?.reduce(
                (acc, product) => acc + +(product.additional_taxes?.reduce((taxesPrice, tax) => taxesPrice + +(tax?.price ?? 0), 0) ?? 0) * product.quantity,
                0,
            );

            if (state.selectedOrder.paymentType === PaymentTypeEnum.Ramburs) {
                total = total + 5;
            }
            if (additionalTaxes !== 0) {
                total = total + additionalTaxes;
            }

            return total;
        }
        return 0;
    });

    readonly updateQuery = this.updater((state, payload: any) => {
        const query = { ...state.query, ...payload };
        if (!query.search || query.search.trim() === '') delete query.search;
        this.router.navigate([], { relativeTo: this.route, queryParams: query });

        return { ...state, query };
    });

    readonly watchOrderIdEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            tap((id: string) => {
                if (id) {
                    if (id === 'noua') {
                        this.patchState({ loading: false, editMode: true, selectedOrder: null });
                    } else {
                        if (this.get().selectedOrder?.id !== id) {
                            this.patchState({ loading: true, editMode: true, addAwb: false, addInvoice: false });
                            this.getOrderEffect(id);
                        }
                    }
                }
            }),
        ),
    );

    readonly getOrdersEffect = this.effect((params: Observable<any>) =>
        params.pipe(
            filter((params) => !!params && Object.keys(params)?.length !== 0),
            switchMap((params) => {
                let decodedParams = new HttpParams();

                for (const [queryKey, queryVal] of Object.entries(params)) {
                    if (typeof queryVal === 'string') {
                        decodedParams = decodedParams.append(queryKey, queryVal);
                    } else {
                        decodedParams = decodedParams.append(queryKey, JSON.stringify(queryVal));
                    }
                }

                return this.http.get<{ count: number; items: Order[] }>('/api/orders/search', { params: decodedParams }).pipe(
                    tap((countAndOrders) => {
                        if (countAndOrders) {
                            this.patchState({ ordersCount: countAndOrders.count, orders: countAndOrders.items });
                        }
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                );
            }),
        ),
    );

    readonly getOrderEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get<Order>(`api/orders/${id || this.get().selectedOrder?.id}`).pipe(
                    tap((order) => {
                        this.patchState({ selectedOrder: order, loading: false });
                        if (!this.get().orderCustomer) {
                            this.getOrderCustomerEffect(order.customerId);
                        }
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getCustomersEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get<Customer[]>('api/customers').pipe(
                    tap((customers) => this.patchState({ customers })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getProductsListEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/products/list?limited=true').pipe(
                    tap((productsList: any) => this.patchState({ productsList })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly deleteOrderEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) => {
                const order = this.get().selectedOrder;

                if (order?.id) {
                    return this.http.delete('/api/orders/' + order.id).pipe(
                        tap((_) => {
                            this.toastr.success('Comanda a fost stearsa');
                            this.patchState({ editMode: false });
                            const timeout = setTimeout(() => {
                                this.router.navigate([WebcoffeeRoutes.orders]);
                                clearTimeout(timeout);
                            }, 500);
                        }),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }

                return EMPTY;
            }),
        ),
    );

    readonly getOrderCustomerEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) => {
                if (id) {
                    return this.http.get<Customer>(`/api/customers/${id}`).pipe(
                        tap((customer) => this.patchState({ orderCustomer: customer })),
                        catchError((err) => {
                            this.toastr.error(err.error.message);
                            return EMPTY;
                        }),
                    );
                }
                return EMPTY;
            }),
        ),
    );

    readonly saveOrderEffect = this.effect(($) =>
        $.pipe(
            tap((_) => this.patchState({ loading: true })),
            exhaustMap((_) => {
                if (this.get().orderFormGroup) {
                    const formValues = this.get().orderFormGroup.getRawValue();

                    const payload = {
                        paymentType: formValues?.order?.paymentType,
                        priority: formValues?.order?.priority,
                        status: formValues?.order?.status,
                        client: { ...formValues?.client, name: formValues?.client.personType ? formValues.client.name : formValues.client.contact },
                        shippingDetails: formValues?.shippingDetails,
                        customerId: formValues?.order?.customer ? formValues?.order?.customer?.id : null,
                        products: formValues?.products?.map((product) => ({
                            product_id: product.product?.id,
                            name: product.name,
                            sku: product.sku,
                            price: product.price,
                            discounted_price: product.discounted_price,
                            tax_percentage: product.tax_percentage,
                            quantity: product.quantity,
                            shipping_cost: product.shipping_cost,
                            additional_taxes: product.additional_taxes,
                        })),
                        scheduledShipping: formValues.scheduledShipping ? formValues.scheduledShipping : undefined,
                    };

                    const selectedOrder = this.get().selectedOrder;

                    if (!selectedOrder) {
                        return this.http.post<Order>('/api/orders', payload).pipe(
                            tap((order) => {
                                this.toastr.success('Comanda a fost salvata cu succes');
                                this.patchState({ selectedOrder: order, loading: false });
                                this.router.navigate([WebcoffeeLinks.orders, order.id]);
                            }),
                            catchError((err) => {
                                this.toastr.error(err.error.message);
                                this.patchState({ loading: false });
                                return EMPTY;
                            }),
                        );
                    } else {
                        return this.http.patch<Order>(`/api/orders/${selectedOrder.id}`, payload).pipe(
                            tap((order) => {
                                this.toastr.success('Comanda a fost modificata cu succes');
                                this.patchState({ selectedOrder: order, loading: false });
                            }),
                            catchError((err) => {
                                this.toastr.error(err.error.message);
                                this.patchState({ loading: false });
                                return EMPTY;
                            }),
                        );
                    }
                }
                this.patchState({ loading: false });
                return EMPTY;
            }),
        ),
    );

    readonly getTaxesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/smartbill/tax').pipe(
                    tap((taxes: any) => this.patchState({ taxes })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );
    readonly getStocksEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/smartbill/stocks').pipe(
                    tap((res: any) => this.patchState({ stocks: res.list })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );
    readonly getRatesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/currency').pipe(
                    tap((lastRate: any) => this.patchState({ rates: lastRate.rates })),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );
    readonly getSeriesEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get('/api/smartbill/series').pipe(
                    tap((res: any) =>
                        this.patchState({
                            series: res.list.reduce((series: { [type: string]: string[] }, serie) => {
                                let parsedType = serie.type;
                                if (parsedType === 'f') {
                                    parsedType = InvoiceTypeEnum.Factura;
                                }
                                if (parsedType === 'c') {
                                    parsedType = 'Chitanta';
                                }
                                if (parsedType === 'p') {
                                    parsedType = InvoiceTypeEnum.Proforma;
                                }

                                if (series[parsedType]) {
                                    series[parsedType] = [...series[parsedType], serie.name];
                                } else {
                                    series[parsedType] = [serie.name];
                                }

                                return series;
                            }, {}),
                        }),
                    ),
                ),
            ),
        ),
    );

    readonly getServicesEffect = this.effect((courierType$: Observable<CourierTypeEnum>) =>
        courierType$.pipe(
            switchMap((courierType) =>
                this.http.get(`/api/shipping/services/${courierType}`).pipe(
                    tap((res: any) => {
                        this.patchState({ services: res?.data });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly refreshPaymentStatusEffect = this.effect((id$: Observable<string>) =>
        id$.pipe(
            switchMap((id) =>
                this.http.get(`/api/payment/${id}`).pipe(
                    tap(() => {
                        this.toastr.info('Plata a fost updatata cu success');
                        this.getOrderEffect(null);
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly getOrderHistoryEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.get(`/api/audit-logs/order/${this.get().selectedOrder?.id}`).pipe(
                    tap((history: any) => {
                        this.patchState({ history });
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );

    readonly sendModifiedOrderEmailEffect = this.effect(($) =>
        $.pipe(
            switchMap((_) =>
                this.http.patch(`/api/orders/${this.get().selectedOrder?.id}/update-email`, {}).pipe(
                    tap((history: any) => {
                        this.toastr.success('Email trimis cu success!');
                    }),
                    catchError((err) => {
                        this.toastr.error(err.error.message);
                        return EMPTY;
                    }),
                ),
            ),
        ),
    );
}
